<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder">{{ $t('invoice.title') }}
          <span class="text-muted pt-2 font-size-sm d-block">{{
              $t('invoice.invoices_count', {n: invoiceTotalCount})
            }}</span>
        </h3>
      </div>
    </div>
    <div v-visibility-change="visibilityChange" class="card-body">
      <div class="row ">
        <div class="col-lg-9 col-xl-8 py-3">
          <form>
            <div class="form-row">
              <div class="col">
                <label>{{ $t('invoice.filter_status') }}</label><br>
                <select class="form-control" v-model="selected_status">
                  <option value="">{{ $t('common.all') }}</option>
                  <option v-for="status in statuses" :value="status.id">{{
                      $t(`invoice.status.${status.name}`)
                    }}
                  </option>
                </select>
              </div>
              <div class="col">
                <label>{{ $t('invoice.started_at') }}</label><br>
                <date-range-picker
                    ref="picker"
                    :show-dropdowns="true"
                    :linked-calendars="true"
                    :close-on-esc="true"
                    :ranges="false"
                    v-model="dateRange"
                    @update="updateDataRange"
                    control-container-class=""
                    :locale-data="$t('data-range-piker')"
                >
                  <div slot="input" slot-scope="data" style="min-width:250px;" class="input-group">
                    <div v-if="data.startDate" type="text" class="form-control"
                         placeholder="Recipient's username" aria-label="Recipient's username"
                         aria-describedby="basic-addon2">{{ data.startDate | formatDate }} -
                      {{ data.endDate | formatDate }}
                    </div>
                    <div v-else type="text" class="form-control"
                         placeholder="Recipient's username" aria-label="Recipient's username"
                         aria-describedby="basic-addon2"></div>
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2"><i
                          class="flaticon-event-calendar-symbol"></i></span>
                    </div>
                  </div>
                  <div slot="footer" slot-scope="data" class="drp-buttons">
                    <span data-v-4f8eb193="" class="drp-selected">{{ data.rangeText }}</span>
                    <button @click="clickDataRangeCencel(data)" type="button"
                            class="cancelBtn btn btn-sm btn-secondary">{{ data.locale.cancelLabel }}
                    </button>
                    <button @click="data.clickApply" v-if="!data.in_selection" type="button"
                            class="applyBtn btn btn-sm btn-success">{{ data.locale.applyLabel }}
                    </button>
                  </div>
                </date-range-picker>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--begin: Datatable-->
      <core-datatable :loaded="loaded" v-bind:columns.sync=columns :showFooter="true"
                      :items="invoiceList" v-bind:filter.sync="filter"
                      :totalCount="invoiceTotalCount">
        <template #created_at="{ item }">
          {{ item.created_at | formatDate }}
        </template>
        <template #period="{ item }">
          <span v-if="item.order">
            {{ item.order.ts_start | formatDate }} - {{ item.order.ts_end | formatDate }}
          </span>
        </template>
        <template #owner_name="{ item }">
          {{ item.owner_name }}
        </template>
        <template #total_cost="{ item }">
          {{ item.total_cost | formatPrice }}
        </template>
        <template #status="{ item, deepFind, getValuesItem, column }">
          <span v-if="item.order" class="label label-lg label-inline"
                :class="'label-light-' + getValuesItem(item.status, column).class">{{
              $t(`invoice.status.${getValuesItem(item.order.status_id, column).name}`)
            }}</span>
        </template>
        <template #action="{ item }">
          <a :href="item.link_to_file" class="btn btn-sm btn-icon btn-light-danger mr-2" target="_blank">
            <font-awesome-icon class="icon-md" icon="download"></font-awesome-icon>
          </a>
        </template>
      </core-datatable>
    </div>
  </div>
</template>


<script>

import {mapMutations, mapGetters} from 'vuex'
import moment from 'moment'
import api from '@/plugins/api'

// https://innologica.github.io/vue2-daterange-picker/#props
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {DateRangePicker},
  computed: {
    ...mapGetters({
      invoiceList: 'invoiceInvoice/list',
      invoicePage: 'invoiceInvoice/page',
      invoicePerPage: 'invoiceInvoice/perPage',
      invoiceTotalCount: 'invoiceInvoice/totalCount',
      loaded: 'invoiceInvoice/loaded'
    })
  },
  data() {
    return {
      paidForm: '',
      dialogCancelModal: false,
      dialogPaidModal: false,
      dateRange: {},
      loading: false,
      valid: false,
      error: null,
      selected: {},
      showInfo: false,
      selected_status: '',
      statuses: [
        {id: 'processed', name: 'processed'},
        {id: 'paid', name: 'paid'},
        {id: 'partially_paid', name: 'partially_paid'},
        {id: 'canceled', name: 'canceled'},
      ],
      columns: [
        {text: 'invoice.columns.id', value: 'id', key: 'id', sort: true},
        {text: 'invoice.columns.number', value: 'number', key: 'number', sort: true},
        {text: 'invoice.columns.owner_name', slot: 'owner_name', key: 'owner_id', sort: false},
        {text: 'invoice.columns.created_at', slot: 'created_at', key: 'created_at', sort: true},
        {text: 'invoice.columns.period', slot: 'period', key: 'period', sort: false},
        {text: 'invoice.columns.cost', slot: 'total_cost', key: 'cost', sort: true},
        {
          text: 'invoice.columns.status', slot: 'status', key: 'status_id', sort: true, values: [
            {id: 5, name: 'paid', class: 'success'},
            {id: 9, name: 'paid', class: 'success'},
            {id: 10, name: 'paid', class: 'success'},
            {id: 6, name: 'canceled', class: 'danger'},
            {id: 1, name: 'processed', class: 'warning'},
            {id: 2, name: 'processed', class: 'warning'},
            {id: 3, name: 'processed', class: 'warning'},
            {id: 4, name: 'processed', class: 'warning'},
            {id: 7, name: 'processed', class: 'warning'},
            {id: 8, name: 'partially_paid', class: 'primary'},
          ]
        },
        {text: 'invoice.columns.action', slot: 'action', sort: false},
      ],
      filter: {
        sort: [],
        statuses: this.$route.query.status ? [this.$route.query.status] : '',
        from: '',
        to: '',
        page: 1,
        'per-page': 20,
      }
    }
  },
  mounted() {
    this.fetch()
  },
  watch: {
    columns: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
    selected_status: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
    filter: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
  },
  methods: {
    clickDataRangeCencel(data) {
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.filter.from = null
      this.filter.to = null
      data.startDate = null
      data.endDate = null
      data.rangeText = ''
      data.clickCancel()
    },
    showDialogModal(item) {

    },
    updateDataRange(params) {
      this.filter.from = moment.unix(params.startDate).format('X') / 1000
      this.filter.to = moment.unix(params.endDate).format('X') / 1000
    },
    fetch() {
      for (let i in this.columns) {
        switch (this.columns[i].sort) {
          case 1:
            this.filter.sort = this.columns[i].key
            break;
          case 2:
            this.filter.sort = '-' + this.columns[i].key
            break;
        }
      }
      switch (this.selected_status) {
        case "processed":
          this.filter.statuses = '1,2,3,4,7';
          break;
        case "paid":
          this.filter.statuses = '5,9,10';
          break;
        case "canceled":
          this.filter.statuses = '6';
          break;
        case "partially_paid":
          this.filter.statuses = '8';
          break;
        default:
          this.filter.statuses = null;
      }

      this.$store.dispatch('invoiceInvoice/GetData', this.filter)
    },
    visibilityChange(evt, hidden) {
      if (!hidden) {
        this.fetch();
      }
    },
  }
}
</script>

<style>

</style>
